<template>
    <div class="json-viewer">
        <div class="controls">
            <span style="margin-right: 10px;">Output</span>
            <button @click="convertToCsv">ConvertToCSV</button>
            <a :href="csvDownloadLink" download="data.csv" v-if="csvDownloadLink">Download CSV</a>
            <button @click="setWrapJson">{{ buttonText }}</button>
            <button @click="copyJson">Copy</button>
        </div>
        <pre ref="jsonPre" v-html="highlightedJson"></pre>
    </div>
</template>

<script>
export default {
    name: 'JsonViewer',
    props: {
        jsonData: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            csvDownloadLink: null,
            isWrappedJson: false,
        };
    },
    computed: {
        highlightedJson() {
            if (this.isWrappedJson) {
                return this.wrapJson(this.jsonData);
            } else {
                return this.syntaxHighlight(this.jsonData);
            }
        },
        buttonText() {
            return this.isWrappedJson ? 'Unwrap' : 'Wrap';
        },
    },

    methods: {
        syntaxHighlight(json) {
            if (typeof json !== 'string') {
                json = JSON.stringify(json, undefined, 2);
            }
            json = json
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
            return this.colorfulJson(json)
        },
        copyJson() {
            const doc = new DOMParser().parseFromString(this.highlightedJson, 'text/html');
            let jsonText = doc.body.textContent || '';
            navigator.clipboard.writeText(jsonText).then(() => {
                // navigator.clipboard.writeText(this.jsonData).then(() => {
                console.log('JSON copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        },
        wrapJson(json) {
            if (json == '') {
                return ""
            }
            if (typeof json !== 'string') {
                json = '{}';
            }
            try {
                const parsedJson = JSON.parse(json);
                json = JSON.stringify(parsedJson);
                return this.colorfulJson(json);
            } catch (error) {
                console.error('Invalid JSON');
            }
        },
        colorfulJson(json) {
            return json.replace(
                /("(\\u[\da-fA-F]{4}|\\[^u]|[^\\"])*"(\s*:)?)|(\b(true|false|null)\b)|(-?\d+(\.\d*)?([eE][+-]?\d+)?)/g,
                (match) => {
                    let cls = 'number';
                    if (/^"/.test(match)) {
                        if (/:$/.test(match)) {
                            cls = 'key';
                        } else {
                            cls = 'string';
                        }
                    } else if (/true|false/.test(match)) {
                        cls = 'boolean';
                    } else if (/null/.test(match)) {
                        cls = 'null';
                    }
                    return `<span class="${cls}">${match}</span>`;
                }
            );
        },
        setWrapJson() {
            this.isWrappedJson = !this.isWrappedJson
        },

        convertToCsv() {
            try {
                const jsonData = JSON.parse(this.jsonData);
                if (!Array.isArray(jsonData)) {
                    alert('JSON data should be an array of objects.');
                    return;
                }

                const csvRows = [];
                const headers = Object.keys(jsonData[0]);
                csvRows.push(headers.join(','));

                for (const row of jsonData) {
                    const values = headers.map(header => {
                        let val = row[header];
                        if (typeof val === 'undefined' || val === null) {
                            val = '';
                        }
                        return typeof val === 'string' ? `"${val.replace(/"/g, '""')}"` : val;
                    });
                    csvRows.push(values.join(','));
                }

                const csvString = csvRows.join('\n');
                this.csvDownloadLink = this.createCsvDownloadLink(csvString);
            } catch (error) {
                alert('Invalid JSON input.');
            }
        },
        createCsvDownloadLink(csvString) {
            const blob = new Blob([csvString], { type: 'text/csv' });
            return URL.createObjectURL(blob);
        },
    }
};
</script>

<style>
.json-viewer {
    float: right;
    width: 48%;
    border-radius: 8px;
    padding: 10px;

    overflow: auto;
    font-family: monospace, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.json-viewer pre {
    height: calc(100vh - 23vh);
    white-space: pre-wrap;
    overflow: auto;
    padding: 10px;

    margin: 0;
    border-radius: 8px;
    border: 1px solid #ccc;
}


.json-viewer .controls {
    margin-bottom: 10px;
}

.json-viewer .controls button {
    float: right;
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
}

.json-viewer pre {
    margin: 0;
    white-space: pre-wrap;
    border-radius: 8px;
}

.json-viewer .string {
    color: #92c06a;
}

.json-viewer .number {
    color: #ad74a3;
}

.json-viewer .boolean {
    color: #b6725e;
}

.json-viewer .null {
    color: #5b89b7;
}

.json-viewer .key {
    color: #429ab3;
}

button {
    margin-right: 10px;
}
</style>
