<template>
    <div class="container">
        <div class="message-container">
            <span class="message">{{ msg }}</span>
        </div>
    </div>
    <!-- <KvCache /> -->
    <!-- <FormateDate /> -->
</template>

<script>
// import KvCache from './database/kvCache.vue';
// import FormateDate from './demo/formateDate.vue';

export default {
    components: {
        // KvCache,
        // FormateDate,
    },
    data() {
        return {
            msg: 'Technology empowers efficiency, tools uplift humanity, creating a more efficient and brighter future for everyone!',
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
}

.message-container {
    border-radius: 8px;
    padding: 20px;
}

.message {
    font-size: 1.5em;
    color: #333;
}
</style>

<!-- road is long ,go slowly -->